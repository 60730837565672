const commonEn = {
   "restaurant": "Restaurant",
   "restaurant_not_found": "The restaurant is not available",
   "table_not_available": "Orders cannot be placed at this table",
   "note": "Note",
   "order": "Order",
   "internal_error": "Request unsuccessful. The server responded with the following error: ",
   "no_results": "No results",
   "cancel": "Cancel",
   "save": "Save",
   "back": "Back",
   "search": "Search",
   "search_for_file_names": "Search for file names",
   "delete": "Delete",
   "search_by_restaurant": "Keresés étterem szerint",
   "available": "Available",
   "unavailable": "Unavailable",
   "name": "Name",
   "status": "Status",
   "description": "Description",
   "price": "Price",
   "add": "Add",
   "phone": "Phone",
   "internal_server_error": "Internal server error",
   "remove_all": "Összes törlése",
   "settings": "Settings",
   "restaurant_name": "Restaurant name",
   "reservation_link": "Reservation link",
   "reservation_link_tooltip": "Only Reservours reservation system links are allowed",
   "add_user": "Add user",
   "add_user_title": "Grant access to restaurant",
   "restaurant_users": "Users with access to the restaurant",
   "restaurant_description_hu": "Restaurant description (hungarian)",
   "restaurant_description_en": "Restaurant description (english)",
   "changes_saved": "Changes were saved successfully",
   "user_added_to_restaurant": "User was granted access to the restaurant",
   "user_add_to_restaurant_tip": "The user with the provided email address will be granted access to the selected restaurant. If the user does not have an account yet, they will automatically receive access after registration.",
   "sign_up_here": "You can create an account here",
   "role_already_exists": "User with this email already has access to the restaurant",
   "error": "Error",
   "header_image": "Header image",
   "upload_image": "Upload image",
   "image_not_set": "Image not set",
}

export default commonEn;