import { RestaurantSetting } from "./RestaurantSetting";
import { User } from "./User";
export enum RoleType {
    MENU_EDITOR = "MENU_EDITOR",
    STAFF_MANAGER = "STAFF_MANAGER",
    SUPER_ADMIN = "SUPER_ADMIN"
}

export interface Role {
    id: number;
    type: RoleType;
    restaurantSetting?: RestaurantSetting;
    user?: User;
}

export interface putRoleDto {
    id?: number;
    type: RoleType;
    restaurantSettingShortName?: string;
}

export function sortRoleByType(a: Role, b: Role): number {
    if (a.type < b.type) {
        return -1;
    }
    if (a.type > b.type) {
        return 1;
    }
    return 0;
}