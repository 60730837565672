const navigationHu = {
    "menu": "Étlap",
    "my_orders": "Rendelés",
    "my_table": "Asztalom",
    "table/all": "Összes",
    "table/pending": "Függő",
    "payment": "Fizetés",
    "terms_and_conditions": "Felhasználási feltételek",
    "back_to_orders": "Oldal bezárása",
    "login": "Login",
    "gallery": "Képek",
    "signup": "Regisztráció",
};

export default navigationHu;