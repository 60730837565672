import { Grid, Menu, Typography } from '@mui/material'
import React from 'react'
import ExpandIcon from '../icons/ExpandIcon'
import { SelectButton } from '../styles/buttons'
import { useTranslation } from 'react-i18next'
import { primary } from '../styles/colors'
import { Allergen, AllergenType } from '../models/Allergens'
import AllergenMenuOption from './AllergenMenuOption'
import { MenuContainer } from '../styles/containers'

type Props = {
    allergens: Allergen[];
    setAllergens: (allergens: Allergen[]) => void;
}

const AllergenAddMenuButton = ({ allergens, setAllergens }: Props) => {
    const allergenTypeList = allergens.map(allergen => allergen.type);
    const { t } = useTranslation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    function onAllergenClick(type: AllergenType) {
        handleClose();
        if (!allergenTypeList.includes(type)) {
            setAllergens([...allergens, { type, id: new Date().getTime() * -1 }]);
        }
    }

    return (
        <div>
            <SelectButton
                onClick={handleClick}
            >
                <Grid container spacing={0.5}>
                    <Grid item>
                            {t("menu.add_allergen")}
                    </Grid>
                    <Grid item>
                        <ExpandIcon style={{ marginBottom: "1px" }} />
                    </Grid>
                </Grid>
            </SelectButton>
            <Menu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                slotProps={{
                    paper: {
                        style: {
                            borderRadius: "13px",
                            border: `1px solid ${primary.dark}`,

                        },
                    }
                }}
            >
                <MenuContainer>
                    {Object.keys(AllergenType)
                        .filter(key => !allergenTypeList.includes(key as AllergenType))
                        .map(key => {
                            return <AllergenMenuOption
                                key={key}
                                type={key as any}
                                onClick={() => onAllergenClick(key as AllergenType)}
                            />
                        })}
                </MenuContainer>
            </Menu>
        </div>
    )
}

export default AllergenAddMenuButton