import './App.css';
import { Route, Routes } from 'react-router';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { 
  confirmSignupRoute, 
  forgottenPasswordRoute, 
  loginPageRoute, 
  menuEditorRoute, 
  resetPasswordRoute, 
  signUpSuccessRoute,
  selfSignupRoute,
} from './config/pageRoutes';
import LoginPage from './pages/LoginPage';
import { AuthProvider } from './contexts/AuthContext';
import Dashboard from './common/DashboardLayout';
import { UserProvider } from './contexts/UserContext';
import { SnackbarProvider } from './contexts/SnackbarContext';
import GalleryPage from './pages/GalleryPage';
import LocalizationHandler from './localization/LocalizationHandler';
import './i18n';
import UsersPage from './pages/UsersPage';
import ConfirmSignupPage from './pages/ConfirmSignupPage';
import ResetPasswordPage from './pages/ResetPasswordPage';
import ForgottenPasswordPage from './pages/ForgottenPasswordPage';
import SignupSuccessPage from './pages/SignupSuccessPage';
import MenuEditorPage from './pages/MenuEditorPage';
import WelcomePage from './pages/WelcomePage';
import SelfSignupPage from './pages/SelfSignupPage';

const darkTheme = createTheme({
  palette: {
    mode: 'light',
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
  }
});

function App() {
  return (
    <div>
      <ThemeProvider theme={darkTheme}>
        <SnackbarProvider>
          <AuthProvider>
            <Routes>
              <Route element={<LocalizationHandler />}>
                <Route element={<UserProvider />}>
                  <Route element={<Dashboard />}>
                  <Route path={"/"} element={<WelcomePage />} />
                    <Route path={menuEditorRoute.path} element={<MenuEditorPage />} />
                  </Route>
                </Route>
                <Route path={loginPageRoute.path} element={<LoginPage />} />
                <Route path={confirmSignupRoute.path} element={<ConfirmSignupPage />} />
                <Route path={resetPasswordRoute.path} element={<ResetPasswordPage />} />
                <Route path={forgottenPasswordRoute.path} element={<ForgottenPasswordPage />} />
                <Route path={signUpSuccessRoute.path} element={<SignupSuccessPage />} />
                <Route path={selfSignupRoute.path} element={<SelfSignupPage />} />
              </Route>
            </Routes>
          </AuthProvider>
        </SnackbarProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
