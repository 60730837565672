import { Box, Container, Grid, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react'
import SnackbarContext from '../contexts/SnackbarContext';
import { useTranslation } from 'react-i18next';
import { LoginForm, LoginPageContainer, LoginPageContainerOverlay } from '../styles/containers';
import LangSelector from '../lang/LangSelector';
import { LoginButton } from '../styles/buttons';
import Footer from '../footer/Footer';
import { validateEmail } from '../models/validate';
import { primary } from '../styles/colors';
import { LoginTextField } from '../styles/inputs';
import { postCreateRestaurant, postShortNameExists } from '../config/apiRoutes';
import { useNavigate } from 'react-router';
import { homePageRoute, loginPageRoute, usePathReconstructor } from '../config/pageRoutes';
import { Link } from 'react-router-dom';

type Props = {}

const SelfSignupPage = (props: Props) => {
    const [name, setName] = useState("");
    const [nameError, setNameError] = useState("");

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState("");

    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");

    const snackbar = useContext(SnackbarContext);
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { copyParamsFromPath } = usePathReconstructor();

    useEffect(() => {
        document.title = `Quickpick ${t("navigation.signup")}`;
    }, []);

    function handleNameChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setName(e.target.value);
    }

    function handleEmailChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setEmail(e.target.value);
    }

    function handlePasswordChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setPassword(e.target.value);
    }

    function handleConfirmPasswordChange(e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) {
        setConfirmPassword(e.target.value);
    }

    useEffect(() => {
        if (name.length < 2) {
            setNameError(t("auth.name_missing"));
        } else {
            setNameError("");
        }
    }, [name]);

    useEffect(() => {
        if (!validateEmail(email)) {
            setEmailError(t("auth.invalid_email"));
        } else {
            setEmailError("");
        }
    }, [email]);

    useEffect(() => {
        if (password.length < 8) {
            setPasswordError(t("auth.password_min_length"));
        } else {
            setPasswordError("");
        }
    }, [password]);

    useEffect(() => {
        if (confirmPassword === "" || confirmPassword !== password) {
            setConfirmPasswordError(t("auth.passwords_do_not_match"));
        } else {
            setConfirmPasswordError("");
        }
    }, [confirmPassword, password]);

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (hasErrors()) return;

        const response = await fetch(postCreateRestaurant, {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({
                name: name,
                email: email,
                password: password,
            })
        });
        if (response.ok) {
            snackbar.showMessage(t("auth.signup_success"), "success");
            navigate(copyParamsFromPath(loginPageRoute.path));
        } else {
            snackbar.showMessage(t("auth.signup_failed"), "error");
        }
    }

    function hasErrors() {
        return nameError.length > 0
        || emailError.length > 0 
        || passwordError.length > 0 
        || confirmPasswordError.length > 0;
    }

    return (
        <>
            <LoginPageContainer>
                <LoginPageContainerOverlay>
                    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
                        <Box pt={2} px={4}>
                            <Grid container>
                                <Grid item>
                                    <Link to={copyParamsFromPath(homePageRoute)}>
                                        <img
                                            src={process.env.REACT_APP_PUBLIC_URL + '/assets/logo.svg'}
                                            width={"80px"}
                                        />
                                    </Link>
                                </Grid>
                                <Grid item xs>
                                </Grid>
                                <Grid item>
                                    <Box pt={1}>
                                        <LangSelector />
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                        <Container style={{ flex: "1", maxWidth: "1600px" }}>
                            <Box pt={3}>
                                <Grid container style={{ justifyContent: "center" }}>
                                    <Grid xs item style={{ maxWidth: "500px" }}>
                                        <LoginForm px={6} pt={3} pb={8}>
                                            <Typography 
                                                fontWeight={"bold"} 
                                                fontSize="30px"
                                                mt={1}
                                                fontFamily="Avenir Next" 
                                                color={primary.deep} 
                                                textAlign={"center"}
                                            >
                                                {t("auth.qp_signup_title")}
                                            </Typography>
                                            <Typography py={2}>
                                                {t("auth.qp_signup_text")}
                                            </Typography>
                                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
                                                <Typography fontSize={"1.4rem"} pb={1} >
                                                    {t("auth.user_information")}
                                                </Typography>
                                                <Box py={1}>
                                                    <LoginTextField
                                                        label={t("auth.name")}
                                                        type="text"
                                                        name="name"
                                                        fullWidth
                                                        value={name}
                                                        onChange={handleNameChange}
                                                        error={nameError.length > 0}
                                                        helperText={nameError}
                                                    />
                                                </Box>
                                                <Box py={1}>
                                                    <LoginTextField
                                                        label={t("auth.email")}
                                                        type="email"
                                                        name="email"
                                                        fullWidth
                                                        value={email}
                                                        onChange={handleEmailChange}
                                                        error={emailError.length > 0}
                                                        helperText={emailError}
                                                    />
                                                </Box>
                                                <Box py={1}>
                                                    <LoginTextField
                                                        label={t("auth.password")}
                                                        type="password"
                                                        name="password"
                                                        fullWidth
                                                        value={password}
                                                        onChange={handlePasswordChange}
                                                        error={passwordError.length > 0}
                                                        helperText={passwordError}
                                                    />
                                                </Box>
                                                <Box py={1}>
                                                    <LoginTextField
                                                        label={t("auth.confirm_password")}
                                                        type="password"
                                                        fullWidth
                                                        name="confirm-password"
                                                        value={confirmPassword}
                                                        onChange={handleConfirmPasswordChange}
                                                        error={confirmPasswordError.length > 0}
                                                        helperText={confirmPasswordError}
                                                    />
                                                </Box>
                                                <LoginButton
                                                    type="submit"
                                                    disabled={hasErrors()}
                                                >
                                                    {t("auth.create_profile")}
                                                </LoginButton>
                                            </Box>
                                        </LoginForm>
                                    </Grid>
                                </Grid>
                            </Box>
                        </Container>
                        <Footer />
                    </div>
                </LoginPageContainerOverlay>
            </LoginPageContainer>
        </>
    )
}

export default SelfSignupPage;