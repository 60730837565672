const menuHU = {
    "add_to_cart": "Hozzáadás a kosárhoz",
    "add_to_order": "Hozzáadás a rendeléshez",
    "already_in_cart": "Már a kosaradban",
    "update_order_item": "Tétel frissítése",
    "delete_order_item": "Tétel eltávolítása",
    "available_categories": "Menü kategóriák",
    "search_menu_placeholder": "Keresés a menüben",
    "guides": {
        "scan_qr": "Scanneld be az asztalon lévő QR kódot a telefonoddal",
        "order": "Rendeld meg a telefonodról a kiválasztott termékeket",
        "pay": "Fizessetek külön vagy egyben a telefonodról",
        "accept": "Tovább az étlapra",
    },
    "index_image": "Indexkép",
    "menu_saved": "Menü mentve",
    "new_category": "Új kategória",
    "menu_editor": "Étlap szerkesztő",
    "new_item": "Új tétel",
    "menu": "Étlap",
    "generate_qr": "QR kód generálása",
    "allergens": "Allergének",
    "allergen_list": {
        "gluten": "Glutén",
        "crustaceans": "Rákfélék",
        "eggs": "Tojás",
        "fish": "Hal",
        "peanuts": "Földimogyoró",
        "soybeans": "Szójabab",
        "milk": "Tej",
        "nuts": "Diófélék",
        "celery": "Zeller",
        "mustard": "Mustár",
        "sesame": "Szezámmag",
        "sulphur_dioxide": "Kén-dioxid",
        "lupin": "Lupin",
        "molluscs": "Puhatestűek",
        "vegan": "Vegán"
    },
    "add_allergen": "Allergén hozzáadása",
}

export default menuHU;