import { Box, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import UserContext from '../contexts/UserContext';
import CustomModal from '../common/CustomModal';
import AddUserForm from '../user/AddUserForm';

type Props = {}

const NoRestaurantSettingModal = (props: Props) => {
    const { t } = useTranslation();
    const { selectedRestaurant } = useContext(UserContext);
    const [noRestaurantSelected, setNoRestaurantSelected] = useState(false);

    useEffect(() => {
        if (!selectedRestaurant) {
            setNoRestaurantSelected(true);
        } else {
            setNoRestaurantSelected(false);
        }
    }, [selectedRestaurant]);

    return (
        <CustomModal
            isOpen={noRestaurantSelected}
            handleClose={() => setNoRestaurantSelected(false)}
            minWidth='400px'
        >
            <Box maxWidth={"400px"} p={2}>
                <Typography variant='h1' fontSize={"1.2rem"} fontWeight={"bold"} mb={2}>{t("auth.no_restaurant_title")}</Typography>
                <Typography mb={2}>{t("auth.no_restaurant_text")}</Typography>
                <Typography>{t("auth.no_restaurant_alt")}</Typography>
            </Box>
        </CustomModal>
    )
}

export default NoRestaurantSettingModal