import { Allergen, allergenComparer } from "./Allergens";
import { MenuItemTranslation } from "./Translation";
import { SerializedVatkey, VatKey, deserializeVatKey } from "./VatKey";

export interface SerializedMenuItem {
    id: number;
    priceGross: string;
    isActive: boolean;
    imageUrl: string | null;
    availabilityType: string;
    translations: MenuItemTranslation[];
    vatKey?: SerializedVatkey;
    order: number;
    allergens: Allergen[];
}

export interface MenuItem {
    id: number;
    priceGross: number;
    isActive: boolean;
    imageUrl: string | null;
    availabilityType: string;
    translations: MenuItemTranslation[];
    vatKey?: VatKey;
    order: number;
    allergens: Allergen[];
}

export function deserializeMenuItem(serialized: SerializedMenuItem): MenuItem {
    return {
        id: serialized.id,
        priceGross: parseFloat(serialized.priceGross),
        isActive: serialized.isActive,
        imageUrl: serialized.imageUrl,
        availabilityType: serialized.availabilityType,
        translations: serialized.translations,
        vatKey: serialized.vatKey != null ? deserializeVatKey(serialized.vatKey) : undefined,
        order: serialized.order,
        allergens: serialized.allergens != null ? serialized.allergens.sort(allergenComparer) : []
    };
}

export function deserializeMenuItems(serialized: SerializedMenuItem[]): MenuItem[] {
    const deserialized = serialized.map(deserializeMenuItem);
    return orderMenuItems(deserialized);
}

export function orderMenuItems(items: MenuItem[]): MenuItem[] {
    return items.sort((a, b) => a.order - b.order);
}

export const defaultMenuItemTranslations: MenuItemTranslation[] = [
    {
        id: -1,
        title: "",
        description: "",
        isoCode: "hu"
    },
    {
        id: -2,
        title: "",
        description: "",
        isoCode: "en"
    }
] 