import { Alert, Box, Typography } from '@mui/material'
import React, { useContext, useEffect, useRef, useState } from 'react'
import { StandardInput, StandardTextArea } from '../styles/inputs';
import { useTranslation } from 'react-i18next';
import { SmallSpecialButton, SpecialButton, StandardButton } from '../styles/buttons';
import SaveIcon from '@mui/icons-material/Save';
import UserContext from '../contexts/UserContext';
import useFetch from '../hooks/useFetch';
import { getRoles, putCreateRestaurantSettings, putRestaurantSettings, uploadRestaurantImage } from '../config/apiRoutes';
import { Role } from '../models/Role';
import CustomModal from '../common/CustomModal';
import AddUserForm from '../user/AddUserForm';
import StandardTextInput from '../common/StandardTextInput';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import { RestaurantImage, RestaurantImageContainer } from '../styles/images';
import { Upload } from '@mui/icons-material';
import { normalizeString } from '../models/common';
import { RestaurantSetting, UpdateRestaurantSetting } from '../models/RestaurantSetting';

type Props = {}

const maxFileSizeMb = 10;

const RestaurantSettings = (props: Props) => {
    const fileInputRef = useRef<HTMLInputElement>(null);
    const { t } = useTranslation();
    const { selectedRestaurant, setSelectedRestaurant, user } = useContext(UserContext);
    const [restaurantName, setRestaurantName] = useState<string>(selectedRestaurant?.name || "");
    const [descriptionHu, setDescriptionHu] = useState<string>(selectedRestaurant?.descriptionHu || "");
    const [descriptionEn, setDescriptionEn] = useState<string>(selectedRestaurant?.descriptionEn || "");
    const [reservationLink, setReservationLink] = useState<string>(selectedRestaurant?.reservationLink || "");
    const [emails, setEmails] = useState<string[]>([]);
    const { call, errorMessage } = useFetch();
    const [isUserModalOpen, setIsUserModalOpen] = useState(false);
    const [isUrlValid, setIsUrlValid] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showError, setShowError] = useState(false);
    const uploader = useFetch(false, true);
    const [imageError, setImageError] = useState<string | null>(null);
    const [imageUrl, setImageUrl] = useState<string>(selectedRestaurant?.image || "");

    useEffect(() => {
        if (selectedRestaurant) {
            getUsers();
        }
    }, []);

    async function getUsers() {
        const response = await call<undefined, Role[]>("GET", getRoles.replace(":restaurantShortName", selectedRestaurant!.restaurantShortName));
        if (response.ok && response.data) {
            const emails = response.data.map(role => role.user?.email || "");
            const emailSet = new Set(emails);
            setEmails(Array.from(emailSet).filter(email => email !== user.email));
        }
    }

    function isSaveDisabled() {
        if (!isUrlValid) {
            return true;
        }
        if (selectedRestaurant === null) {
            if (restaurantName !== "" && descriptionHu !== "" && descriptionEn !== "") {
                return false;
            }
            return true;
        }
        if (restaurantName === selectedRestaurant?.name) {
            return false;
        }
        if (reservationLink === selectedRestaurant?.reservationLink) {
            return false;
        }
        if (descriptionHu === selectedRestaurant?.descriptionHu) {
            return false;
        }
        if (descriptionEn === selectedRestaurant?.descriptionEn) {
            return false;
        }
        return true;
    }

    useEffect(() => {
        if (reservationLink === "") {
            return setIsUrlValid(true);
        }
        if (!reservationLink.includes("https://reservours.com")) {
            return setIsUrlValid(false);
        }
        return setIsUrlValid(true);
    }, [reservationLink]);

    function handleDescriptionKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
        if (e.key === "Enter") {
            e.preventDefault();
        }
    }

    function createShortName(name: string) {
        return normalizeString(name)
            .trim()
            .replaceAll(" ", "-")
            .replace(/[^a-zA-Z0-9-]/g, "");
    }

    async function handleSave() {
        if (isSaveDisabled()) return;
        if (selectedRestaurant) {
            handleUpdate();
        } else {
            handleCreate();
        }
    }

    async function handleUpdate() {
        if (!selectedRestaurant) return;
        const response = await call<UpdateRestaurantSetting, RestaurantSetting>("PUT", putRestaurantSettings.replace(":restaurantShortName", selectedRestaurant!.restaurantShortName), {
            name: restaurantName,
            descriptionHu,
            descriptionEn,
            reservationLink,
            image: imageUrl,
        });
        if (response.ok && response.data) {
            setShowSuccess(true);
            setShowError(false);
            setSelectedRestaurant(response.data);
        } else {
            setShowError(true);
            setShowSuccess(false);
        }
    }

    async function handleCreate() {
        const response = await call<UpdateRestaurantSetting, RestaurantSetting>("PUT", putCreateRestaurantSettings.replace(":restaurantShortName", createShortName(restaurantName)), {
            name: restaurantName,
            descriptionHu,
            descriptionEn,
            reservationLink,
            image: imageUrl,
        });
        if (response.ok) {
            if (response.data) {
                setShowSuccess(true);
                setShowError(false);
                setSelectedRestaurant(response.data);
            }
        } else {
            setShowError(true);
            setShowSuccess(false);
        }
    }

    function onUserAdded(email: string) {
        setEmails([...emails, email]);
    }

    async function handleFileUpload(e: React.ChangeEvent<HTMLInputElement>) {
        if (!e.target.files) return;
        if (selectedRestaurant == null) return;
        const sizeInBytes = e.target.files[0].size / 1024 / 1024;
        if (sizeInBytes > maxFileSizeMb) {
            setImageError(t("gallery.maximum_file_size"))
            return false;
        }
        const formData = new FormData();
        formData.append("file", e.target.files[0]);

        const response = await uploader.call<any, any>("POST", uploadRestaurantImage.replace(":restaurantShortName", selectedRestaurant.restaurantShortName), formData);
        if (response.ok && response.data) {
            setImageUrl(response.data.originalUrl);
            setImageError(null);
            return true;
        } else {
            return false;
        }
    }

    function handleFileUploadClick() {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    }

    return (
        <Box>
            <Typography fontSize="20px" fontWeight={"bold"}>
                {t("common.settings")}
            </Typography>
            <Box>
                <Typography fontSize={"13px"} fontWeight={600} mb={0.5}>
                    {t("common.restaurant_name")}
                </Typography>
                <StandardInput
                    placeholder={t("common.name")}
                    value={restaurantName}
                    onChange={(e) => setRestaurantName(e.target.value)}
                />
            </Box>
            <Typography fontSize={"13px"} fontWeight={600} mb={0.5} mt={1}>
                {t("common.header_image")}
            </Typography>
            {imageUrl && (
                <RestaurantImageContainer>
                    <RestaurantImage
                        src={imageUrl}
                        alt="invalid_image"
                        title={t("common.header_image")}
                    />
                </RestaurantImageContainer>
            )}
            {!imageUrl && (
                <Alert severity='info' variant="filled">
                    {t("common.image_not_set")}
                </Alert>
            )}
            <Box mt={1}>
                <SmallSpecialButton
                    onClick={handleFileUploadClick}
                    disabled={selectedRestaurant === null}
                >
                    {t("common.upload_image")}
                    <Upload className="end-icon" />
                </SmallSpecialButton>
            </Box>
            {imageError && (
                <Alert severity='error' variant="filled">
                    {imageError}
                </Alert>
            )}
            <input
                ref={fileInputRef}
                style={{ display: "none" }}
                type="file"
                accept="image/*"
                onChange={handleFileUpload} />
            <Box mt={1}>
                <Typography fontSize={"13px"} fontWeight={600} mb={0.5}>
                    {t("common.restaurant_description_hu")}
                </Typography>
                <StandardTextArea
                    value={descriptionHu}
                    onChange={(e) => setDescriptionHu(e.target.value)}
                    style={{ height: "60px" }}
                    onKeyDown={handleDescriptionKeyDown}
                />
            </Box>
            <Box>
                <Typography fontSize={"13px"} fontWeight={600} mb={0.5}>
                    {t("common.restaurant_description_en")}
                </Typography>
                <StandardTextArea
                    value={descriptionEn}
                    onChange={(e) => setDescriptionEn(e.target.value)}
                    style={{ height: "60px" }}
                    onKeyDown={handleDescriptionKeyDown}
                />
            </Box>
            <Box mt={1}>
                <StandardTextInput
                    label={t("common.reservation_link")}
                    inputProps={{
                        value: reservationLink,
                        onChange: (e) => setReservationLink(e.target.value),
                        placeholder: "URL"
                    }}
                    error={!isUrlValid}
                    helperText={t("common.reservation_link_tooltip")}
                />
            </Box>
            <Box mt={1}>
                <StandardButton
                    disabled={isSaveDisabled()}
                    onClick={handleSave}
                >
                    {t("common.save")}
                    <SaveIcon className="end-icon" />
                </StandardButton>
            </Box>
            <Box mt={1}>
                {showSuccess && (
                    <Alert severity='success' variant='filled'>
                        {t("common.changes_saved")}
                    </Alert>
                )}
                {showError && (
                    <Alert severity='error' variant='filled'>
                        {t("common.internal_server_error")}: {errorMessage}
                    </Alert>
                )}
            </Box>
            {selectedRestaurant && (
                <Box mt={2}>
                    <Typography fontSize="16px" fontWeight={"bold"}>
                        {t("common.restaurant_users")}
                    </Typography>
                    <Box>
                        <ul>
                            {emails.map(email => (
                                <li key={email}>
                                    {email}
                                </li>
                            ))}
                        </ul>
                    </Box>
                    <SpecialButton
                        onClick={() => setIsUserModalOpen(true)}
                    >
                        {t("common.add_user")}
                        <PersonAddAlt1Icon className="end-icon" />
                    </SpecialButton>
                </Box>
            )}

            <CustomModal
                isOpen={isUserModalOpen}
                handleClose={() => setIsUserModalOpen(false)}
                minWidth='400px'
            >
                <Box maxWidth={"400px"}>
                    <AddUserForm
                        onUserAdded={onUserAdded}
                    />
                </Box>
            </CustomModal>
        </Box>
    )
}

export default RestaurantSettings