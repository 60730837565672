import React, { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> { }

const CloseIcon = (props: Props) => {
    return (
        <svg width="6" height="7" {...props} viewBox="0 0 6 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.99 3.50019L5.84618 1.64401C6.05127 1.43892 6.05127 1.10642 5.84618 0.901654L5.59873 0.654201C5.39358 0.449048 5.06108 0.449048 4.85631 0.654201L3.00019 2.51032L1.14401 0.653817C0.938922 0.448728 0.606422 0.448728 0.401654 0.653817L0.153817 0.901269C-0.0512722 1.10642 -0.0512722 1.43892 0.153817 1.64369L2.01032 3.50019L0.154201 5.35631C-0.0509518 5.56146 -0.0509518 5.89396 0.154201 6.09873L0.401654 6.34618C0.606742 6.55127 0.939242 6.55127 1.14401 6.34618L3.00019 4.49L4.85631 6.34618C5.06146 6.55127 5.39396 6.55127 5.59873 6.34618L5.84618 6.09873C6.05127 5.89358 6.05127 5.56108 5.84618 5.35631L3.99 3.50019Z" fill="#995C00" />
        </svg>

    )
}

export default CloseIcon