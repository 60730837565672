import styled from "styled-components";
import { background, captionText, highlight, lightErrorColor, paleErrorColor, primary, secondary } from "./colors";
import { ButtonProps, IconButton, styled as muiStyled } from "@mui/material";

export const OptionsButton = styled.button`
    height: 50px;
    width: 50px;
    border-radius: 50%;
    border: none;
    background: ${background.deep};
    padding-top: 5px;
    cursor: pointer;
    transition: all 0.2s;
    font-family: Avenir Next;
    &:focused{
        outline: none; 
    }
    &:hover{
        background: ${primary.pale};
        box-shadow: 0px 0px 5px 0px ${primary.pale};
    }
`;

export const StandardButton = styled.button`
    background: ${primary.dark};
    color: white;
    border: none;
    border-radius: 30px;
    font-weight: bold;
    font-size: 16px;
    padding: 14px 28px;
    cursor: pointer;
    transition: all 0.2s;
    font-family: Avenir Next;
    &:focused {
        outline: none;
    }   
    &:hover{
        background: ${primary.deep};
        box-shadow: 0px 0px 2px 0px ${primary.deep};
    }
    .end-icon{
        font-size: 20px;
        margin-bottom: -5px;
        margin-left: 10px;
    }
    &:disabled{
        background: ${background.light};
        color: ${captionText};
        cursor: default;
        &:hover{
            box-shadow: none;
        }
    }
`;

export const StandardSmallButton = styled.button`
    background: ${primary.dark};
    color: white;
    border: none;
    border-radius: 20px;
    font-weight: bold;
    font-size: 14px;
    padding: 11px 16px;
    cursor: pointer;
    transition: all 0.2s;
    font-family: Avenir Next;
    &:focused {
        outline: none;
    }   
    &:hover{
        background: ${primary.deep};
        box-shadow: 0px 0px 2px 0px ${primary.deep};
    }
    .end-icon{
        font-size: 18px;
        margin-bottom: -6px;
        margin-left: 10px;
    }
`;

export const SpecialButton = styled.button`
    background: ${highlight.deep};
    color: black;
    border: none;
    border-radius: 30px;
    font-weight: bold;
    font-size: 16px;
    padding: 14px 28px;
    cursor: pointer;
    transition: all 0.2s;
    font-family: Avenir Next;
    &:focused {
        outline: none;
    }   
    &:hover{
        background: ${secondary.main};
        box-shadow: 0px 0px 4px 0px ${secondary.main};
    }
    .end-icon{
        font-size: 20px;
        margin-bottom: -5px;
        margin-left: 10px;
    }
`;

export const SmallSpecialButton = styled.button`
    background: ${highlight.deep};
    color: black;
    border: none;
    border-radius: 30px;
    font-weight: bold;
    font-size: 14px;
    padding: 11px 16px;
    cursor: pointer;
    transition: all 0.2s;
    font-family: Avenir Next;
    &:focused {
        outline: none;
    }
    &:hover{
        background: ${secondary.main};
        box-shadow: 0px 0px 4px 0px ${secondary.main};
    }
    .end-icon{
        font-size: 18px;
        margin-bottom: -3px;
        margin-left: 10px;
    }
    &:disabled{
        background: ${background.light};
        color: ${captionText};
        cursor: default;
        &:hover{
            box-shadow: none;
        }
    }
`;

export const TabButton = styled.button`
    background: ${props => props.theme.isSelected ? primary.pale : 'none'};
    color: ${props => props.theme.isSelected ? "black" : captionText};
    border: none;
    cursor: pointer;
    font-size: 16px;
    padding: 14px 28px;
    border-radius: 30px;
    transition: all 0.2s;
    &:focused {
        outline: none;
    }
    &:hover{
        background: ${props => props.theme.isSelected ? primary.pale : background.dark};
    }
`;

export const LangSelectTextfieldContainer = styled.div`
    width: 100%;
    background: white;
    padding: 10px 12px;
    border-radius: 10px;
`;

export const LangSelectTextfieldInput = styled.input`
    border: none;
    width: 100%;
    font-size: 16px;
    &:focus{
        outline: none;
    }
`;

export const LangSelectLangButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
    border-radius: 50%;
    box-shadow: 0px 0px 2px 2px ${props => props.theme.isSelected ? primary.light : "none"};
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    margin-top: 3px;
    &:focused{
        outline: none;
    }
    &:hover{
  
    }
`;

export const ErrorIconButton = muiStyled(IconButton)`
    background: ${lightErrorColor};
    &:hover{
        background: ${paleErrorColor};
    }
`;

interface CustomButtonProps extends ButtonProps {
    open: boolean;
}

export const CollapseIconButton = muiStyled(IconButton, {
    shouldForwardProp: (prop) => prop !== 'open',
})<CustomButtonProps>(({ theme, open }) => ({
    backgroundColor: open ? background.light : primary.dark,
    color: open ? primary.deep : "white",
    '&:hover': {
        backgroundColor: open ? primary.light : primary.deep,
    },
}));

export const MenuIndexIconButton = styled.button`
    position: absolute;
    bottom: 0px;
    right: 0;
    border: none;
    background: ${secondary.pale};
    padding: 7px;
    border-radius: 20px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    transition: all 0.2s;
    &:hover{
        background: ${highlight.deep};
        box-shadow: 0px 0px 1px 1px ${highlight.dark};
    }
`;

export const LoginButton = styled.button`
    background: ${secondary.pale};
    color: #8B5300;
    font-family: 'Avenir Next';
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    width: 100%;
    border-radius: 30px;
    padding: 18px 36px;
    border: none;
    outline: none;
    cursor: pointer;
    transition: all 0.3s;
    &:hover{
        background: ${secondary.light};
    }

    &:disabled{
        background: ${background.light};
        color: ${captionText};
        cursor: not-allowed;
    }
`;

export const GoogleLoginButton = styled.button`
    background: ${background.deep};
    color: ${primary.deep};
    width: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0;
    transition: all 0.4s;
    border-radius: 30px;
    &:hover{
        background: ${primary.pale};
    }
`;

export const GoogleLoginButtonTextContainer = styled.div`
    font-family: 'Avenir Next';
    font-weight: bold;
    font-size: 20px;
    padding: 18px 36px;
`;

export const GoogleLoginIconContainer = styled.div`
    background: ${primary.dark};
    border-radius: 50%;
    font-size: 24px;
    color: white;
    height: 60px;
    width: 60px;
    svg{
        margin-top: 16px;
        margin-right: 18px;
    }
`;

export const ButtonSelect = styled.button`
    background: ${primary.dark};
    color: white;
    border: none;
    font-family: 'Avenir Next';
    font-weight: bold;
    font-size: 16px;
    padding-top: 13px;
    padding-bottom: 9px;
    padding-left: 20px;
    padding-right: 10px;
    border-radius: 5px;
    cursor: pointer;
    svg{
        margin-top: -2px;
        margin-left: 10px;
    }
`;

export const SelectButton = styled.button`
    border: 1px solid ${primary.dark};
    color: ${primary.dark};
    border-radius: 300px;
    padding-top: 7px;
    padding-left: 10px;
    padding-bottom: 7px;
    padding-right: 8px;
    background: ${background.light};
    cursor: pointer;
    line-height: 12px;
    font-size: 12px;
    font-weight: bold;
    &:hover{
        background: ${background.dark};
    }
`;

export const SecondaryAddButton = styled.div`
    position: relative;
    background: ${secondary.pale};
    border-radius: 300px;
    border: none;
    padding: 0;
    height: 20px;
    width: 20px;
    svg{
         
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
    }
`;

export const AllergenRemoveButton = muiStyled(IconButton)`
    padding: 3px;
    background: ${secondary.pale};
    margin-top: -2px;
    &:hover{
        background: ${secondary.light};
    }
`;

export const AllergenRemoveAllButton = muiStyled(IconButton)`
    padding: 3px;
    background: ${highlight.light};
    margin-top: -2px;
    &:hover{
        background: ${highlight.light};
    }
`;

export const SmallLightBlueButton = styled.button`
    background: ${background.main};
    color: black;
    border: none;
    border-radius: 30px;
    font-weight: bold;
    font-size: 14px;
    padding: 11px 16px;
    cursor: pointer;
    transition: all 0.2s;
    font-family: Avenir Next;
    &:focused {
        outline: none;
    }
    &:hover{
        background: ${background.dark};
        box-shadow: 0px 0px 4px 0px ${background.dark};
    }
    .end-icon{
        font-size: 18px;
        margin-bottom: -3px;
        margin-left: 10px;
    }
`;