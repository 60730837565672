export const primary = {
    pale: "#66EAFD",
    light: "#57C8D9",
    main: "#4399A6",
    dark: "#2E6A73",
    deep: "#1A3B40"
}

export const background = {
    pale: "#FFFFFF",
    light: "#F2FEFF",
    main: "#DCF9FD",
    dark: "#C5F6FD",
    deep: "#A4F2FD",
}

export const secondary = {
    pale: "#FFD232",
    light: "#FFB800",
    main: "#FF9E0D",
    dark: "#CC7A00",
    deep: "#995C00"
}

export const highlight = {
    pale: "#FFFFFF",
    light: "#FFFBEB",
    main: "#FFF2C2",
    dark: "#FFE895",
    deep: "#FFDD63"
}

export const faded = "#EDEDEDEE";
export const disabled = "#8C8C8C";
export const inputBg = "#DEF1F4";
export const captionText = "#717171";

//export const secondary = "#087E8B";
export const primaryText = "#000";
export const secondaryText = "#3E635C";
export const success = "#075810";
export const successLight = "#d0fcc7";
export const errorColor = "#b00b0b";
export const lightErrorColor = "#ffebeb";
export const paleErrorColor = "#ffc7c7";
export const inputBorder = "#b2e4eb";
export const backgroundAlt = "#fefdfc";
export const fadedBlue = "#ccdbdd";
export const grayBg = "#F2FEFF";

export function hexToRgbA(hex: string, opacity = 1) {
    var c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
        c = hex.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        //@ts-ignore
        return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + `,${opacity})`;
    }
    throw new Error('Bad Hex');
}
