import { Box, Grid, Typography } from '@mui/material'
import React, { MutableRefObject, useContext, useRef, useState } from 'react'
import SearchInput from '../common/SearchInput'
import { SpecialButton, StandardButton } from '../styles/buttons'
import { MenuHeaderDivider } from '../styles/header';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import UserContext from '../contexts/UserContext';
import SelectButton from '../common/SelectButton';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useParams } from 'react-router-dom';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import { QRCode } from 'react-qrcode-logo';
import { primary } from '../styles/colors';
import CustomModal from '../common/CustomModal';
import SettingsIcon from '@mui/icons-material/Settings';
import RestaurantSettings from './RestaurantSettings';

type Props = {
    onSave: () => void;
    onNewCategoryClick: () => void;
    search: string;
    setSearch: (search: string) => void;
}

const MenuEditorHeader = (props: Props) => {
    const { t } = useTranslation();
    const { selectedRestaurant, restaurantSettings, setSelectedRestaurant } = useContext(UserContext);
    const params = useParams();
    const qrRef = useRef<QRCode>();
    const [isSettingsOpen, setIsSettingsOpen] = useState(false);

    function selectOptions() {
        return restaurantSettings.map((restaurantSetting) => {
            return {
                value: restaurantSetting.restaurantShortName,
                label: restaurantSetting.name
            }
        });
    }

    function handleRestaurantChange(value: string | null) {
        const restaurant = restaurantSettings.find(restaurant => restaurant.restaurantShortName === value);
        if (restaurant) {
            setSelectedRestaurant(restaurant);
        }
    }

    function getMenuUrl() {
        return `${process.env.REACT_APP_FRONTEND_URL}/menu/${selectedRestaurant?.restaurantShortName}/${params.lang || "hu"}`;
    }

    function handleMenuButtonClick() {
        if (selectedRestaurant == null) return;
        window.open(getMenuUrl(), '_blank');
    }

    function handleQRButtonClick() {
        qrRef.current?.download('png', `${selectedRestaurant?.restaurantShortName}-menu-qr-code.png`);
    }

    return (
        <>
            <Box pr={1}>
                <Grid container spacing={2}>
                    <Grid item xs>
                        <Typography variant="h4" fontWeight={"bold"} fontSize="26px">
                            {t("menu.menu_editor")}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <SearchInput
                            search={props.search}
                            setSearch={props.setSearch}
                        />
                    </Grid>
                </Grid>
                <Box pt={4}>
                    <Grid container spacing={2}>
                        {restaurantSettings.length > 1 && (
                            <Grid item xs>
                                <SelectButton
                                    options={selectOptions()}
                                    onChange={handleRestaurantChange}
                                    selectedValue={selectedRestaurant != null ? selectedRestaurant.restaurantShortName : null}
                                />
                            </Grid>
                        )}
                        {restaurantSettings.length < 2 && (
                            <Grid item xs>
                                <Grid container spacing={3}>
                                    <Grid item>
                                        <Typography pt={2} variant="h4" fontWeight={"bold"} fontSize="20px">
                                            {selectedRestaurant?.name}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Grid>
                        )}
                        <Grid item>
                            <StandardButton
                                onClick={props.onSave}
                            >
                                {t("common.save")}
                            </StandardButton>
                        </Grid>
                        <Grid item>
                            <SpecialButton
                                onClick={handleMenuButtonClick}
                            >
                                {t("menu.menu")}
                                <OpenInNewIcon className="end-icon" />
                            </SpecialButton>
                        </Grid>
                        <Grid item>
                            <SpecialButton
                                onClick={handleQRButtonClick}
                            >
                                {t("menu.generate_qr")}
                                <QrCode2Icon className="end-icon" />
                            </SpecialButton>
                            <QRCode
                                style={{ display: "none" }}
                                ref={qrRef as MutableRefObject<QRCode>}
                                value={getMenuUrl()}
                                ecLevel='H'
                                size={300}
                                fgColor={primary.dark}
                                logoImage='../assets/logo-dark.svg'
                                logoPadding={8}
                                logoPaddingStyle='circle'
                                removeQrCodeBehindLogo={true}
                                qrStyle='squares'
                            />
                        </Grid>
                        <Grid item>
                            <SpecialButton
                                onClick={props.onNewCategoryClick}
                            >
                                {t("menu.new_category")}
                                <AddIcon className="end-icon" />
                            </SpecialButton>
                        </Grid>
                        <Grid item>
                            <StandardButton
                                onClick={() => setIsSettingsOpen(true)}
                            >
                                {t("common.settings")}
                                <SettingsIcon className="end-icon" />
                            </StandardButton>
                        </Grid>
                    </Grid>
                </Box>
                <Box pt={2}>
                    <MenuHeaderDivider />
                </Box>
            </Box>
            <CustomModal
                isOpen={isSettingsOpen}
                handleClose={() => setIsSettingsOpen(false)}
                minWidth='500px'
            >
                <Box maxWidth={"500px"}>
                    <RestaurantSettings />
                </Box>
            </CustomModal>
        </>
    )
}

export default MenuEditorHeader