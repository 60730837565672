import { Link } from "react-router-dom";
import styled from "styled-components";
import { highlight, primary } from "./colors";

export const PrimaryLink = styled(Link)`
    color: white;
`;

export const HightlightedLink = styled.a`
    color: ${highlight.deep};
    text-decoration: none;
`;

export const SocialLink = styled.a`
    color: black;
    text-decoration: none;
    transition: 0.3s color;
    &:hover{
        color: ${primary.dark};
    }
`;

export const SocialLinkContent = styled.div`
    background: white !important;
    border-radius: 30px;
`;

export const FooterLink = styled(Link)`
    color: white;
    font-family: 'Avenir Next';
    font-size: 12px;
    text-decoration: none;
    transition: 0.3s color;
    &:hover{
        color: ${primary.light};
    }
`;

export const StandardLink = styled(Link)`
    font-size: 15px;
    color: ${primary.dark};
    &:hover{
        color: ${primary.main};
    }
`;