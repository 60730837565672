import React from 'react'
import { StandardInput } from '../styles/inputs'
import { Typography } from '@mui/material'
import { captionText, errorColor } from '../styles/colors'
import { ThemeProvider } from 'styled-components';

interface Props {
    inputProps: React.InputHTMLAttributes<HTMLInputElement>
    error?: boolean;
    helperText?: string;
    label?: string;
}

const StandardTextInput = (props: Props) => {
    return (
        <ThemeProvider theme={{
            error: props.error
        }}>
            {props.label && (
                <Typography fontSize={"13px"} fontWeight={600} mb={0.5}>
                    {props.label}
                </Typography>
            )}
            <StandardInput
                {...props.inputProps}
            />
            {props.helperText && (
                <Typography fontSize="12px" color={props.error ? errorColor : captionText} pt={0.5}>
                    {props.helperText}
                </Typography>
            )}

        </ThemeProvider>
    )
}

export default StandardTextInput