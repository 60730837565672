const menuEn = {
    "add_to_cart": "Add to cart",
    "add_to_order": "Add to order",
    "already_in_cart": "Already in your cart",
    "update_order_item": "Update order",
    "delete_order_item": "Remove item",
    "available_categories": "Menu categories",
    "search_menu_placeholder": "Search in the menu",
    "guides": {
        "scan_qr": "Scan the QR code on the table with your phone",
        "order": "Order the selected items from your phone",
        "pay": "Pay separately or all at once from your phone",
        "accept": "Continue",
    },
    "index_image": "Index image",
    "menu_saved": "Menu saved",
    "new_category": "New category",
    "menu_editor": "Menu editor",
    "new_item": "New item",
    "menu": "Menu",
    "allergens": "Allergens",
    "generate_qr": "Generate QR code",
    "allergen_list": {
        "gluten": "Gluten",
        "crustaceans": "Crustaceans",
        "eggs": "Eggs",
        "fish": "Fish",
        "peanuts": "Peanuts",
        "soybeans": "Soybeans",
        "milk": "Milk",
        "nuts": "Nuts",
        "celery": "Celery",
        "mustard": "Mustard",
        "sesame": "Sesame",
        "sulphur_dioxide": "Sulphur dioxide",
        "lupin": "Lupin",
        "molluscs": "Molluscs",
        "vegan": "Vegan"
    },
    "add_allergen": "Add allergen",
}

export default menuEn;