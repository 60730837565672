import commonEn from "../en/commonEn";

const commonHu: typeof commonEn = {
    "restaurant": "Étterem",
    "restaurant_not_found": "A keresett étterem nem elérhető",
    "table_not_available": "Ennél az asztalnál nem adható le rendelés",
    "note": "Megjegyzés",
    "order": "Rendelés",
    "internal_error": "Kérés sikertelen. A szerver oldali hiba: ",
    "no_results": "Nincs találat",
    "cancel": "Mégse",
    "save": "Mentés",
    "back": "Vissza",
    "search": "Keresés",
    "search_for_file_names": "Keresés file név szerint",
    "delete": "Törlés",
    "search_by_restaurant": "Keresés étterem szerint",
    "available": "Elérhető",
    "unavailable": "Nem elérhető",
    "name": "Név",
    "status": "Állapot",
    "description": "Leírás",
    "price": "Ár",
    "add": "Hozzáadás",
    "phone": "Telefon",
    "internal_server_error": "Internal server error",
    "remove_all": "Összes törlése",
    "settings": "Beállítások",
    "restaurant_name": "Étterem neve",
    "reservation_link": "Foglalási link",
    "reservation_link_tooltip": "Csak a Reservours foglalási rendszer linkjei engedélyezettek",
    "add_user": "Felhasználó hozzáadása",
    "add_user_title": "Felhasználó hozzáadása az étteremhez",
    "restaurant_users": "Étteremhez hozzáférő felhasználók",
    "restaurant_description_hu": "Étterem rövid leírása (magyar)",
    "restaurant_description_en": "Étterem rövid leírása (angol)",
    "changes_saved": "Változtatások sikeresen mentve",
    "user_added_to_restaurant": "Felhasználó hozzáadva az étteremhez",
    "user_add_to_restaurant_tip": "A megjelölt e-mail címmel rendelkező felhasználó hozzáférést fog kapni a kijelölt étteremhez. Amennyiben a felhasználó még nem rendelkezik felhasználói fiókkal, a regisztráció után automatiksuan rendelkezni fog az itt hozzáadott hozzáféréssel.",
    "sign_up_here": "Felhasználó létrehozása",
    "role_already_exists": "A felhasználó már rendelkezik hozzáféréssel az étteremhez",
    "error": "Hiba",
    "header_image": "Header kép",
    "upload_image": "Kép feltöltése",
    "image_not_set": "Nincs kép beállítva",
}

export default commonHu;
