import React from 'react'
import { AllergenType } from '../models/Allergens'
import { useTranslation } from 'react-i18next';
import { Box, Grid, MenuItem, Stack, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { SecondaryAddButton } from '../styles/buttons';
import { primary, secondary } from '../styles/colors';

type Props = {
    type: AllergenType;
    onClick: () => void;
}

const AllergenMenuOption = (props: Props) => {
    const { t } = useTranslation();
    return (
        <MenuItem
            onClick={props.onClick}
        >
            <Grid container columnSpacing={3}>
                <Grid item xs>
                    <Typography color={primary.dark} fontSize="14px" p={0}>
                        {t(`menu.allergen_list.${props.type.toLowerCase()}`)}
                    </Typography>
                </Grid>
                <Grid item>
                    <Box pt={0}>
                        <SecondaryAddButton>
                            <AddIcon
                                style={{ color: secondary.deep, height: "18px", width: "18px" }}
                            />
                        </SecondaryAddButton>
                    </Box>
                </Grid>
            </Grid>
        </MenuItem>
    )
}

export default AllergenMenuOption