import { Alert, Box, Typography } from '@mui/material'
import React, { useContext, useEffect, useState } from 'react'
import { StandardButton } from '../styles/buttons'
import { useTranslation } from 'react-i18next'
import { captionText } from '../styles/colors'
import useFetch from '../hooks/useFetch'
import { putAddUserToRestaurant } from '../config/apiRoutes'
import StandardTextInput from '../common/StandardTextInput'
import { selfSignupRoute, usePathReconstructor } from '../config/pageRoutes'
import { StandardLink } from '../styles/links'
import AddIcon from '@mui/icons-material/Add';
import { validateEmail } from '../models/validate'
import UserContext from '../contexts/UserContext'

type Props = {
    onUserAdded: (email: string) => void;
}

const AddUserForm = (props: Props) => {
    const { copyParamsFromPath } = usePathReconstructor();
    const { selectedRestaurant } = useContext(UserContext);
    const { t } = useTranslation();
    const [email, setEmail] = useState<string>('');
    const [isEmailValid, setIsEmailValid] = useState<boolean>(false);
    const [showSuccess, setShowSuccess] = useState<boolean>(false);
    const [showError, setShowError] = useState<boolean>(false);
    const { call, errorMessage } = useFetch();

    useEffect(() => {
        setIsEmailValid(validateEmail(email));
    }, [email]);

    function handleEmailChange(e: React.ChangeEvent<HTMLInputElement>) {
        setEmail(e.target.value);
    }

    async function handleSubmit() {
        if (selectedRestaurant == null) return;
        const response = await call<any, undefined>("PUT", putAddUserToRestaurant.replace(":restaurantShortName", selectedRestaurant.restaurantShortName), { email });
        if (response.ok) {
            setShowSuccess(true);
            setShowError(false);
            props.onUserAdded(email);
        } else {
            setShowSuccess(false);
            setShowError(true);
        }
    }

    return (
        <Box>
            <Typography textAlign={"center"} fontWeight={"bold"} fontSize={"20px"} pb={1}>
                {t("common.add_user_title")}
            </Typography>
            <Box mb={2}>
                <Typography fontSize="13px" color={captionText} >
                    {t("common.user_add_to_restaurant_tip")}
                </Typography>
                <StandardLink
                    to={copyParamsFromPath(selfSignupRoute.path)}
                    target={"_blank"}
                >
                    {t("common.sign_up_here")}
                </StandardLink>
            </Box>
            <StandardTextInput
                inputProps={{
                    value: email,
                    onChange: handleEmailChange
                }}
                label={t("auth.email")}
                error={!isEmailValid && email.length > 0}
                helperText={!isEmailValid && email.length > 0 ? t("auth.invalid_email") : undefined}
            />
            <Box mt={1}>
                <StandardButton
                    disabled={!isEmailValid}
                    onClick={handleSubmit}
                    style={{ width: "100%" }}
                >
                    {t("common.add_user")}
                    <AddIcon className='end-icon' />
                </StandardButton>
            </Box>
            <Box my={1}>
                {showSuccess && (
                    <Alert severity='success' variant="filled">
                        {t("common.user_added_to_restaurant")}
                    </Alert>
                )}
                {showError && (
                    <Alert severity='error' variant="filled" >
                        {t("common.error")}: {t(`common.${errorMessage}`)}
                    </Alert>
                )}
            </Box>
        </Box>
    )
}

export default AddUserForm