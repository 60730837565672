import { Box, Grid, IconButton, Modal, Paper, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { GrayContainer, MainContainer } from '../styles/containers';

type Props = {
    children?: any;
    isOpen: boolean;
    handleClose: () => void;
    title?: string;
    widthPercent?: number;
    minHeightPercent?: number;
    containerId?: string;
    size?: "fixed" | "auto";
    minWidth?: string;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: "none",
    borderRadius: "16px",
    overflow: "hidden",
}

const CustomModal = (props: Props) => {
    return (
        <>
            <Modal
                open={props.isOpen}
                onClose={props.handleClose}
                style={{ width: "100%" }}
            >
                <Box style={{
                    ...style,
                    width: props.widthPercent ? `${props.widthPercent}%` : "auto",
                    height: props.minHeightPercent ? `${props.minHeightPercent}%` : "auto",
                    minWidth: props.minWidth ? props.minWidth : "500px",
                }}>
                    <GrayContainer>
                        <Box textAlign={"right"}>
                            <IconButton onClick={props.handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        <div
                            id={props.containerId ? props.containerId : undefined}
                            style={{
                                maxHeight: `${window.innerHeight - 200}px`,
                                minHeight: props.minHeightPercent ? `${props.minHeightPercent}%` : undefined,
                                overflowY: "auto"
                            }}>
                            <Box px={2} pb={2} pt={1}>
                                {props.children}
                            </Box>
                        </div>
                    </GrayContainer>
                </Box>
            </Modal>
        </>
    );
}

export default CustomModal