import React from 'react'
import { Allergen } from '../models/Allergens'
import { AllergenBadgeContainer } from '../styles/inputs';
import { Grid } from '@mui/material';
import { AllergenRemoveButton } from '../styles/buttons';
import CloseIcon from '../icons/CloseIcon';
import { useTranslation } from 'react-i18next';

type Props = {
    allergen: Allergen;
    onClose: () => void;
}

const AllergenBadge = ({ allergen, onClose }: Props) => {
    const { t } = useTranslation();
    return (
        <AllergenBadgeContainer>
            <Grid container spacing={0.5}>
                <Grid item xs>
                    {t(`menu.allergen_list.${allergen.type.toLowerCase()}`)}
                </Grid>
                <Grid item>
                    <AllergenRemoveButton
                        onClick={onClose}
                    >
                        <CloseIcon
                            style={{ width: "10px", height: "10px" }}
                        />
                    </AllergenRemoveButton>
                </Grid>
            </Grid>
        </AllergenBadgeContainer>
    )
}

export default AllergenBadge