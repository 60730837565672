import mainEn from "../en/mainEn";
import authHu from "./authHu";
import commonHu from "./commonHu";
import footerHu from "./footerHu";
import galleryHu from "./galleryHu";
import menuHU from "./menuHu";
import navigationHu from "./navigationHu";
import orderHu from "./orderHu";
import paymentHu from "./paymentHu";

const mainHu: typeof mainEn = {
    common: commonHu,
    navigation: navigationHu,
    menu: menuHU,
    order: orderHu,
    payment: paymentHu,
    auth: authHu,
    gallery: galleryHu,
    footer: footerHu,
}

export default mainHu;