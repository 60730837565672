import React from 'react'
import { SelectorContainer } from '../styles/containers'
import { Allergen } from '../models/Allergens'
import AllergenBadge from './AllergenBadge';
import { Box, Grid } from '@mui/material';
import { AllergenAllBadgeContainer } from '../styles/inputs';
import { AllergenRemoveAllButton } from '../styles/buttons';
import CloseIcon from '../icons/CloseIcon';
import { useTranslation } from 'react-i18next';

type Props = {
    allergens: Allergen[];
    setAllergens: (allergens: Allergen[]) => void;
}

const AllergenSelector = ({ allergens, setAllergens }: Props) => {
    const { t } = useTranslation();
    function handleCloseClick(allergen: Allergen) {
        setAllergens(allergens.filter(a => a.id !== allergen.id))
    }

    function handleRemoveAllClick() {
        setAllergens([]);
    }

    return (
        <SelectorContainer>
            <Grid container spacing={1}>
                {allergens.map(allergen => {
                    return <Grid item key={allergen.id}>
                        <AllergenBadge
                            allergen={allergen}
                            onClose={() => handleCloseClick(allergen)}
                        />
                    </Grid>
                })}
            </Grid>
            {allergens.length > 1 && <Box width="fit-content" mt={1}>
                <AllergenAllBadgeContainer onClick={handleRemoveAllClick}>
                    <Grid container spacing={0.5}>
                        <Grid item xs>
                            {t(`common.remove_all`)}
                        </Grid>
                        <Grid item>
                            <AllergenRemoveAllButton>
                                <CloseIcon
                                    style={{ width: "10px", height: "10px" }}
                                />
                            </AllergenRemoveAllButton>
                        </Grid>
                    </Grid>
                </AllergenAllBadgeContainer>
            </Box>}
        </SelectorContainer>
    )
}

export default AllergenSelector