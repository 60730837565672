import styled from "styled-components";
import { primary } from "./colors";

export const TileImage = styled.img`
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: scale-down;
    background: rgba(255, 255, 255, 0.1);
    border-radius: 5px;
`;

export const MenuItemIndexImageContainer = styled.div`
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    aspect-ratio: 1/1;
    background: ${primary.dark};
`;

export const MenuItemIndexImage = styled.img`
    width: 100%;
    border-radius: 20px;
    aspect-ratio: 1/1;
    object-fit: cover;
`;

export const RestaurantImageContainer = styled.div`
    position: relative;
    border-radius: 20px;
    overflow: hidden;
    aspect-ratio: 16/9;
    background: ${primary.dark};
`;

export const RestaurantImage = styled.img`
    width: 100%;
    aspect-ratio: 16/9;
    object-fit: cover;
    border-radius: 5px;
`;