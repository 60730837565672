import styled from "styled-components";
import { background, errorColor, hexToRgbA, highlight, inputBg, inputBorder, primary, secondary } from "./colors";
import { TextField } from "@mui/material";
import { styled as muiStyled } from "@mui/material/styles";

export const StandardInput = styled.input`
    width: 100%;
    border: 1px solid ${props => props.theme.error ? errorColor : inputBorder};
    font-size: 14px;
    padding: 8px 15px;
    border-radius: 16px;
    height: 34px;
    &:focus{
        outline: none;
        box-shadow: 0px 0px 2px 2px ${props => props.theme.error ? hexToRgbA(errorColor, 0.6) : inputBorder};
    }
`;

export const UnitOfMeasureInput = styled.input`
    width: 100%;
    border: 1px solid ${inputBorder};
    font-size: 12px;
    padding: 8px 15px;
    padding-right: 40px;
    border-radius: 16px;
    height: 34px;
    &:focus{
        outline: none;
        box-shadow: 0px 0px 2px 2px ${inputBorder};
    }
`;

export const StandardTextArea = styled.textarea`
    width: 100%;
    border: 1px solid ${inputBorder};
    font-size: 14px;
    padding: 8px 15px;
    border-radius: 16px;
    height: 34px;
    &:focus{
        outline: none;
        box-shadow: 0px 0px 2px 2px ${inputBorder};
    }
    resize: none;
    size: 12px;
    font-family: 'Roboto', sans-serif;
`;

export const NumericInputContainer = styled.div`
    width: 100%;
    border: 1px solid ${inputBorder};
    font-size: 14px;
    padding: 6px 15px;
    border-radius: 16px;
    height: 34px;
    background: white;
    box-shadow: ${props => props.theme.isFocused ? `0px 0px 2px 2px ${inputBorder}` : 'none'};
`;

export const UnitOfMeasureText = styled.span`
    font-weight: bold;
    padding-left: 5px;
    padding-right: 5px;
`;

export const NumericInputStyle = styled.input`
    width: 100%;
    border: none;
    font-size: 14px;
    &:focus{
        outline: none;  
    }
    &::-webkit-inner-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
    } 
    &::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
    }
`;

export const NumericStepButton = styled.button`
    background: white;
    border: none;
    height: 10px;
    width: 16px;
    padding 2px;
    margin: 0;
    display: block;
    position: relative;
    transition: background 0.3s;
    svg{
        font-size: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

    }
    &:hover{
        background: ${background.deep};
    }
`;

export const LangSelectButton = styled.button`
    border-radius: 50px;
    cursor: pointer;
    padding: 0;
    background: none;
    transition: border 0.4s;
    border: 5px solid ${props => props.theme.isSelected ? primary.pale : 'transparent'};
    &:hover{
        border: 5px solid ${props => props.theme.isSelected ? primary.pale : primary.main};
    }
    width: 40px;
    height: 40px;
    img{
        width: 100%;
    }
`;

export const LoginTextInput = styled.input`
    width: 100%;
    border: none;
    padding: 28px 20px;
    border-radius: 30px;
    height: 34px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 700;
    background: ${inputBg};
    color: #557276;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    &:focus{
        outline: none;
        box-shadow: 0px 0px 2px 2px ${inputBorder};
    }
`;

export const SecondaryInput = styled.input`
    height: 34px;
    width: 100%;
    background: #DCF9FD;
    padding: 28px 20px;
    border: none;
    border-radius: 30px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #557276;
    box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    &:focus{
        outline: none;
        box-shadow: 0px 0px 2px 2px ${inputBorder};
    }
`;

export const AllergenBadgeContainer = styled.div`
    background: ${highlight.main};
    padding: 5px 7px 5px 9px;
    border-radius: 16px;
    font-size: 14px;
    color: ${secondary.deep};
    font-weight: bold;
`;

export const AllergenAllBadgeContainer = styled.div`
    background: ${secondary.pale};
    padding: 5px 7px 5px 9px;
    border-radius: 16px;
    font-size: 14px;
    color: ${secondary.deep};
    font-weight: bold;
    cursor: pointer;
    &:hover{
        background: ${secondary.main};
    }
`;
export const LoginTextField = muiStyled(TextField)({
    margin: 5,
    "& .MuiInputLabel-root:not(.MuiInputLabel-shrink)": {
        transform: "translate(20px, 20px) scale(1)"
    },
    "& .MuiInputLabel-root": {
        color: "#557276"
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#557276",
        border: "none",
        outline: "none",
    },
    "& .MuiInputBase-root": {
        color: "#557276",
        background: inputBg,
        width: "100%",
        padding: "20px",
        borderRadius: "30px",
        fontFamily: 'Inter',
        fontSize: "16px",
        fontWeight: "700",
    },
    "& .MuiInputBase-input": {
        padding: 0,
    },
    "& .MuiOutlinedInput-notchedOutline": {
        borderColor: inputBorder,
    },
});
