import React, { SVGProps } from 'react'

interface Props extends SVGProps<SVGSVGElement> { }

const ExpandIcon = (props: Props) => {
    return (
        <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.34254 5.86003C5.16908 6.04525 4.88635 6.04685 4.71097 5.86362L0.132552 1.08009C-0.0428193 0.896858 -0.0443734 0.598221 0.12908 0.413003L0.338464 0.1894C0.511918 0.00418099 0.794697 0.00257235 0.970075 0.185806L5.01923 4.41638L9.02415 0.139974C9.19761 -0.0452445 9.48034 -0.0468548 9.65572 0.136379L9.86744 0.357586C10.0428 0.540819 10.0444 0.839457 9.87092 1.02468L5.34254 5.86003Z" fill="#2E6A73" />
        </svg>
    )
}

export default ExpandIcon